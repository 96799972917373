import React, { useState } from "react"
import { Link } from "gatsby"

import NavLinks from "./nav-links"
import "./layout.css"
import Logo from "../../content/assets/shady-grove-blacksmith.gif"
import CreditCards from "../../content/assets/credit-card-group.png"
import useKeyPress from "../hooks/useKeyPress"

const Layout = props => {
  const { children, updatedDate } = props
  const [isToggled, setIsToggled] = useState(false)
  const enterPress = useKeyPress("Enter")
  const Menu = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-menu"
    >
      <line x1="3" y1="12" x2="21" y2="12"></line>
      <line x1="3" y1="6" x2="21" y2="6"></line>
      <line x1="3" y1="18" x2="21" y2="18"></line>
    </svg>
  )
  const X = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-x"
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  )

  const handleKeyPress = () => {
    if (enterPress) setIsToggled(!isToggled)
  }

  const header = (
    <>
      <div className="mobile-container">
        <Link className="home-link" to={`/`}>
          <img
            alt="Shady Grove Blacksmith"
            src={Logo}
            className="header__logo"
            style={{ maxWidth: "85%", display: "block", margin: "32px auto" }}
          />
          <h1 className="mobile-title">Shady Grove Blacksmth Shop</h1>
        </Link>
        <div
          className="menu-icon"
          onClick={() => setIsToggled(!isToggled)}
          role="button"
          aria-pressed={isToggled}
          tabIndex={0}
          onKeyPress={handleKeyPress}
        >
          {isToggled ? X : Menu}
        </div>
      </div>
      <NavLinks />
      <div className="header__payments">
        <p className="bold">Prefferred Payment Methods:</p>
        <p>ACH & Checks</p>
        <p className="bold">Alternative Methods (+ 3% Fee)</p>
        <Link to="/payments">
          <img src={CreditCards} alt="Payment Methods" />
        </Link>
      </div>
    </>
  )

  return (
    <div>
      <div className="flex-container wrapper">
        <header className={`header ${isToggled ? "header--is-open" : ""}`}>
          {header}
        </header>
        <main>{children}</main>
      </div>
      <footer>
        <span>
          ©1999 - {new Date().getFullYear()}, SHADY GROVE BLACKSMITH SHOP, LLC
        </span>
        {updatedDate && <span>Updated: {updatedDate}</span>}
      </footer>
    </div>
  )
}

export default Layout
