import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import SEO from "./seo"

const NavLinks = () => {
  const navLinks = useStaticQuery(graphql`
    query SiteNavQuery {
      site {
        siteMetadata {
          siteNav {
            name
            link
          }
        }
      }
    }
  `)

  const isActive = link => {
    const url = typeof window !== `undefined` ? window.location.href : ""
    const splitUrl = url.split("/")
    const splitLink = link.split("/")

    return splitUrl[splitUrl.length - 1] === splitLink[splitLink.length - 1]
  }

  return (
    <>
      <SEO title="All Pages" />
      <nav role="navigation">
        <ul>
          {navLinks.site.siteMetadata.siteNav.map(item => {
            return (
              <li key={item.link}>
                <Link
                  style={{ boxShadow: `none` }}
                  className={isActive(item.link) ? "active" : ""}
                  to={item.link}
                >
                  {item.name}
                </Link>
              </li>
            )
          })}
        </ul>
      </nav>
    </>
  )
}

export default NavLinks
